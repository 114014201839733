import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Header from '../../../components/Header';
import { usePageMeta } from '../../../services/usePageMeta';
import { requireShowroomCode } from '../../../services/showrooms/requireShowroomCode';
import CurrentPromoBanner from '../../../components/CurrentPromoBanner';
import Container from '../../../components/Container';
import { Col, Row } from 'react-grid-system';
import styles from '../index.module.scss';
import Footer from '../../../components/Footer';
import { useCurrency } from '../../../services/formatCurrency';
import Button from '../../../components/Button';
import classnames from 'classnames';

type DataType = {
  image: string;
  title: string;
  price: number;
  tags: Array<{ label: string; color: string }>;
  cta: {
    label: string;
    action: string;
  };
};

export default function ConfiguratorListing(props) {
  const { d } = useCurrency();

  const pageMeta = usePageMeta({
    title: 'Build your own CUPRA | CUPRA',
    addTitlePrefix: false,
    description: '',
  });

  const evColor = '#01555B';
  const petrolColor = '#94572B';

  const items: DataType[] = [
    {
      image:
        'https://ik.imagekit.io/vyro/Cupra-NZ_Configurator/listing/1704242485193-838099_7dejO-gzS.jpg?updatedAt=1705021751173',
      title: 'BORN',
      price: 59_900_00,
      tags: [{ label: 'Electric', color: evColor }],
      cta: {
        label: 'Configure Born',
        action: '/configurator/born',
      },
    },
    {
      image:
        'https://ik.imagekit.io/vyro/Cupra-NZ_Configurator/listing/1702448192062-formentor_K307GEuvmB.jpeg?updatedAt=1705021750164',
      title: 'FORMENTOR',
      price: 56_900_00,
      tags: [
        { label: 'Plug-In Hybrid', color: evColor },
        { label: 'Petrol', color: petrolColor },
      ],
      cta: {
        label: 'Configure Formentor',
        action: '/configurator/formentor',
      },
    },
    {
      image:
        'https://ik.imagekit.io/vyro/Cupra-NZ_Configurator/listing/1702444919167-leon_NjI0NUjDS.jpeg?updatedAt=1705021750168',
      title: 'LEON',
      price: 53_900_00,
      tags: [{ label: 'Petrol', color: petrolColor }],
      cta: {
        label: 'Configure Leon',
        action: '/configurator/leon',
      },
    },
    {
      image:
        'https://ik.imagekit.io/vyro/Cupra-NZ_Configurator/listing/1704241134926-Cupra%20Leon%20Sportstourer_ljubd0VxY.jpeg?updatedAt=1705021750052',
      title: 'LEON SPORTSTOURER',
      price: 55_900_00,
      tags: [
        { label: 'Plug-in Hybrid', color: evColor },
        { label: 'Petrol', color: petrolColor },
      ],
      cta: {
        label: 'Configure Leon ST',
        action: '/configurator/leon-sportstourer',
      },
    },
    {
      image:
        'https://ik.imagekit.io/vyro/Cupra-NZ_Configurator/listing/1702446327068-ateca_z8rtUebzKH.jpeg?updatedAt=1705021750382',
      title: 'ATECA',
      price: 75_900_00,
      tags: [{ label: 'Petrol', color: petrolColor }],
      cta: {
        label: 'Configure Ateca',
        action: '/configurator/ateca',
      },
    },
  ];

  return (
    <div>
      <Head>{pageMeta}</Head>
      <Header />

      <CurrentPromoBanner />
      <main className={styles.main}>
        <section>
          <Container>
            {items.map((item, index) => (
              <Row key={index}>
                <Col md={7} className={classnames(styles.listingCol, styles.imageCol)}>
                  <img src={item.image} width={'100%'} />
                </Col>
                <Col md={5} className={styles.listingCol}>
                  <div className={styles.listingDescription}>
                    <h3 className="hug">{item.title}</h3>
                    <div>
                      <p>From {d(item.price)} + ORC</p>
                      <div className={styles.tagContainer}>
                        {item.tags.map((tag, tagIdx) => (
                          <span key={tagIdx} className={styles.tag} style={{ backgroundColor: tag.color }}>
                            {tag.label}
                          </span>
                        ))}
                      </div>
                      <Button color={'primary'} isBlock href={item.cta.action}>
                        {item.cta.label}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
          </Container>
        </section>
        <div className={styles.bottomSection}></div>
        <section>
          <Container>
            <Row>
              <Col md={12}>
                <h2>Disclaimer</h2>
              </Col>
              <Col md={12}>
                <p>
                  1) CUPRA New Zealand may change the Maximum Retail Price (MRP), specifications, features and model availability at any time. Contact your closest CUPRA Garage for up-to-date prices. MRP excludes any optional extras and on road costs.
                </p>
                <p>2) Images for illustrative purposes only.</p>
                <p>
                  3) The official fuel consumption, emissions, and EV range figures determined by the WLTP test should
                  be used as a guide only, or for vehicle-to-vehicle comparison. Actual results may vary due to factors
                  such as driving or environmental conditions. Range / CO2 calculated according to the NZ base
                  specification vehicle as agreed with NZ Transport Agency.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = requireShowroomCode('cupra-nz', async (context) => {
  return {
    props: {},
  };
});
